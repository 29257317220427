import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import AdobeLogo from '../components/partner-logos/AdobeLogo';
// import CommercetoolsLogo from '../components/partner-logos/CommercetoolsLogo';
import ShopifyLogo from '../components/partner-logos/ShopifyLogo';
import SalesforceCommerceCloudLogo from '../components/partner-logos/SalesforceCommerceCloudLogo';
import ZoeyLogo from '../components/partner-logos/ZoeyLogo';
import WooCommerceLogo from '../components/partner-logos/WooCommerceLogo';
import KlaviyoLogo from '../components/partner-logos/KlaviyoLogo';
import YotpoLogo from '../components/partner-logos/YotpoLogo';
import ShipperHqLogo from '../components/partner-logos/ShipperHqLogo';
import AvalaraLogo from '../components/partner-logos/AvalaraLogo';
import CybersourceLogo from '../components/partner-logos/CybersourceLogo';
import WorldpayLogo from '../components/partner-logos/WorldpayLogo';
import PayPalLogo from '../components/partner-logos/PayPalLogo';
import BraintreeLogo from '../components/partner-logos/BraintreeLogo';
import AdyenLogo from '../components/partner-logos/AdyenLogo';
import TopBrandsSection from '../components/sections/TopBrandsSection';

const ReviewSubscribeProPage = () => (
  <Layout>
    <Seo title="Help us and others out by leaving your review" />

    <Header graphic={3}>Review Subscribe Pro</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="prose prose-lg max-w-none prose-headings:font-normal prose-h2:text-4xl prose-h3:text-3xl">
          <h2>Help us and others out by leaving your review</h2>
          <p>
            We would love to hear about your experience with Subscribe Pro. Please consider leaving a review on one of the
            following platforms:
          </p>
          <ul>
            <li>
              <Link
                to="https://appexchange.salesforce.com/appxListingDetail?listingId=b76a256b-d22a-4d86-bb37-3e6939e2d376"
                className="text-sp-100"
              >
                Salesforce App Exchange
              </Link>
            </li>
            <li>
              <Link to="https://www.g2.com/products/subscribe-pro/reviews" className="text-sp-100">
                G2
              </Link>
            </li>
            <li>
              <Link to="https://www.trustpilot.com/review/subscribepro.com" className="text-sp-100">
                Trustpilot
              </Link>
            </li>
            <li>
              <Link to="https://www.capterra.com/p/168472/Subscribe-Pro/reviews/" className="text-sp-100">
                Capterra
              </Link>
            </li>
            <li>
              <Link to="https://g.page/r/CfeIdPXUcFdpEAI/review" className="text-sp-100">
                Google Business
              </Link>
            </li>
            <li>
            <Link to="https://www.glassdoor.com/Overview/Working-at-Subscribe-Pro-EI_IE7173090.11,24.htm" className="text-sp-100">
              Glassdoor
            </Link>
          </li>
          </ul>
        </div>
      </Container>
    </section>

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default ReviewSubscribeProPage;
